import { useContext, useEffect } from "react";
import { Button, Col, Form, Input, Row, Space } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Can from "@components/shared/D3Can";
import { ItemListContactType } from "./type";
import { UpdateContactForm } from "./UpdateContactForm ";
import { Icon } from "@iconify/react";
import { D3Date } from "@utils/date";
import FormItemPhone from "@components/shared/FormItemPhone";
import FormItemLandLine from "@components/shared/FormItemLandLine";

type ViewContactFormProps = {
  contact: ItemListContactType;
};

export const ViewContactForm = ({ contact }: ViewContactFormProps) => {
  const { onClose, openDrawer } = useContext(D3DrawerContext);

  const [form] = Form.useForm<ItemListContactType>();

  useEffect(() => {
    (async () => {
      form.setFieldsValue({
        ...contact,
        created_at: D3Date.FormatValueApiToPtBR(contact.created_at as Date),
        updated_at: D3Date.FormatValueApiToPtBR(contact.updated_at as Date),
      });
    })();
  }, [contact]);

  return (
    <Form className="select-none form-view " form={form} layout="vertical" disabled autoComplete="off">
      <Form.Item<ItemListContactType> name="description" label="Descrição">
        <Input />
      </Form.Item>
      <Form.Item<ItemListContactType> name="name" label="Nome">
        <Input />
      </Form.Item>
      <Form.Item<ItemListContactType> name={["contact_type", "description"]} label="Tipo de contato">
        <Input />
      </Form.Item>
      <Form.Item<ItemListContactType> hidden={!contact.email} name="email" label="Email">
        <Input />
      </Form.Item>
      {contact.user && (
        <Form.Item<ItemListContactType> name={["user", "name"]} label="Usuário">
          <Input
            disabled={true}
            addonAfter={
              contact.user.active && (
                <Icon
                  color={contact.user.active ? "#22c55e" : "#ef4444"}
                  icon={contact.user.active ? "basil:unlock-solid" : "basil:lock-solid"}
                />
              )
            }
          />
        </Form.Item>
      )}
      <Row gutter={20} hidden={!contact.landline}>
        <Col>
          <FormItemLandLine disabled defaultValue={contact.landline} label="Telefone fixo" name="land_line" />
        </Col>
        <Col>
          <Form.Item<ItemListContactType> hidden={!contact.extension} name="extension" label="Ramal">
            <Input className="w-32" />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!contact.phone}>
        <FormItemPhone disabled value={contact.phone} label="Celular" name="phone" />
      </Row>
      <Form.Item<ItemListContactType> name="created_by" label="Criado por">
        <Input />
      </Form.Item>
      <Form.Item<ItemListContactType> name="created_at" label="Criado em">
        <Input />
      </Form.Item>
      <Form.Item<ItemListContactType> name="updated_by" label="Atualizado por">
        <Input />
      </Form.Item>
      <Form.Item<ItemListContactType> name="updated_at" label="Atualizado em">
        <Input />
      </Form.Item>

      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={false} type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="manager" resource="all">
              <Button
                disabled={false}
                type="primary"
                onClick={() => openDrawer(<UpdateContactForm contact={contact} />, "right", 600, "Editar contato")}
              >
                Editar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
};
