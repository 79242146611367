import { Alert, Button, Form, InputNumber, Select, Space, Spin, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { CellsConfigurator, SelectRepositoryTypeItemType } from "../types";
import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import cellStore from "../store";
import StatusSelect from "@components/shared/StatusSelect";

function CellsConfiguratorForm() {
  const { onClose } = useContext(D3DrawerContext);
  const { selected, getRepositoryTypeForSelect, ApplyConfigurationCell } = cellStore();

  const [loading, setLoading] = useState<boolean>(false);

  const [repositoryTypes, setRepositoryTypes] = useState<SelectRepositoryTypeItemType[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const repositoryTypesList = await getRepositoryTypeForSelect();
      setRepositoryTypes(repositoryTypesList);
      setLoading(false);
    })();
  }, [getRepositoryTypeForSelect]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          autoComplete="off"
          onFinish={async (payload: CellsConfigurator) => {
            setLoading(true);
            await ApplyConfigurationCell(payload);
            setLoading(false);
            onClose();
          }}
        >
          <Form.Item>
            <Alert
              className="p-3"
              showIcon
              description={`Você está prestes a modificar a Estante. Esta ação impactará ${
                selected.total.length === 1
                  ? selected.total.length + ` Vão/Prateleira`
                  : selected.total.length + ` Vãos/Prateleiras`
              }. Por favor, confirme as configurações desejadas e, se estiver certo da alteração, selecione 'Atualizar' para prosseguir com a operação.`}
              type="info"
            />
          </Form.Item>
          <Form.Item<CellsConfigurator>
            name="repository_type_cuid"
            label="Tipo de Repositório"
            rules={[
              {
                required: true,
                message: "Por favor, selecione o tipo de repositório.",
              },
            ]}
          >
            <Select
              listItemHeight={10}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
              optionLabelProp="dataLabel"
              showSearch
              maxTagCount="responsive"
              size="middle"
              className="w-full truncate select-none"
              optionFilterProp="children"
              loading={loading}
              filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
            >
              {repositoryTypes.map(({ value, active, label }) => (
                <Select.Option disabled={!active} key={value} value={value} dataFilter={label} dataLabel={label}>
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{label}</span>
                    </div>
                    <StatusSelect status={active} />
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item<CellsConfigurator>
            name="number_of_positions"
            label="Qtd. de posições"
            rules={[
              {
                validator: (_, value) =>
                  value && value >= 1
                    ? Promise.resolve()
                    : Promise.reject(new Error("Por favor, insira uma quantidade de posições igual ou superior a 1.")),
              },
            ]}
          >
            <InputNumber min={1} type="number" />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="customer_storage">
                  <Button type="primary" htmlType="submit" disabled={selected.total.length === 0}>
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
}

export default CellsConfiguratorForm;
