import { Layout, Spin } from "antd";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AbilityContext } from "@context/CASLContext";
import { D3UploadProvider } from "@context/D3Upload/D3UploadContext";
import D3HeaderBar from "./components/Layout/D3HeaderBar/D3HeaderBar";
import D3SiderBar from "./components/Layout/D3SiderBar/D3SiderBar";
import D3Drawer from "./components/shared/D3Drawer";
import useAuthStore from "./provider/AuthProvider";
import { D3DrawerProvider } from "./provider/D3DrawerContext";

const App = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [customerCuid, setCustomerCuid] = useState<string>("");
	const { isAuthenticated, getAbility, token, getCustomerCuid } = useAuthStore();
	useEffect(() => {
		if (!isAuthenticated) {
			navigate("/auth/login");
		} else {
			let customerCuidNew = getCustomerCuid();
			if (customerCuidNew !== undefined) {
				setCustomerCuid(customerCuidNew);
			}
		}
		setIsLoading(false);
	}, [isAuthenticated, token, getCustomerCuid, navigate]);

	return isAuthenticated ? (
		<AbilityContext.Provider value={getAbility()}>
			<D3DrawerProvider>
				<D3UploadProvider>
					<Layout className={`flex z-0 relative h-full bg-gray-100 ${isLoading ? `justify-center items-center` : ``}`}>
						{isLoading ? (
							<Spin spinning={isLoading} size="large" />
						) : (
							<>
								<D3SiderBar />
								<Layout className="flex flex-col w-full h-full bg-gray-100">
									<D3HeaderBar />
									<Outlet key={customerCuid} />
								</Layout>
							</>
						)}
					</Layout>
				</D3UploadProvider>
				<D3Drawer />
			</D3DrawerProvider>
		</AbilityContext.Provider>
	) : (
		<></>
	);
};

export default App;
