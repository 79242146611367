import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { D3Date } from "@utils/date";
import showMessage from "@utils/showMessage";
import { Button, Form, Input, InputNumber, Space, Spin } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { CurrencyInput } from "react-currency-mask";
import { useEventsStore } from "../store";
import { ViewEventType } from "../types";
import { UpdateEventForm } from "./UpdateEventForm";

type ViewEventFormProps = {
  cuid: string;
};

export const ViewEventForm = ({ cuid }: ViewEventFormProps) => {
  const { getEvent } = useEventsStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm<ViewEventType>();

  useEffect(() => {
    (async () => {
      const result = await getEvent(cuid);
      if (result.status === "success") {
        const viewEvent = result.body as ViewEventType;
        form.setFieldsValue({
          ...result.body,
          created_at: D3Date.FormatValueApiToPtBR(viewEvent.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewEvent.updated_at),
          event_date: dayjs(viewEvent.event_date).format("DD/MM/YYYY"),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getEvent]);

  const { onClose } = useContext(D3DrawerContext);

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none" layout="vertical" form={form} autoComplete="off">
          <Form.Item<ViewEventType> name="description" label="Descrição">
            <Input disabled />
          </Form.Item>
          <div className="grid grid-cols-2 gap-5">
            <div>
              <Form.Item<ViewEventType> name={["customer", "fantasy_name"]} label="Cliente">
                <Input disabled />
              </Form.Item>
              <Form.Item<ViewEventType> name={["department", "description"]} label="Departamento">
                <Input disabled />
              </Form.Item>
            </div>

            <div>
              <Form.Item<ViewEventType> name="invoice_id" label="Nº Nota Fiscal">
                <Input disabled />
              </Form.Item>
              <Form.Item<ViewEventType> name="event_date" label="Data do evento">
                <Input disabled />
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-between gap-5">
            <Form.Item<ViewEventType> name="quantity" label="Quantidade">
              <InputNumber disabled />
            </Form.Item>
            <Form.Item<ViewEventType> name="unit_value" label="Valor unitário">
              <CurrencyInput
                value={form.getFieldValue("unit_value")}
                locale="pt-BR"
                currency="BRL"
                onChangeValue={() => {}}
                InputElement={<InputNumber className="min-w-max" disabled />}
              />
            </Form.Item>
            <Form.Item<ViewEventType> name="value" label="Valor total">
              <CurrencyInput
                value={form.getFieldValue("value")}
                locale="pt-BR"
                currency="BRL"
                onChangeValue={() => {}}
                InputElement={<InputNumber className="min-w-max" disabled />}
              />
            </Form.Item>
          </div>

          <div className="grid grid-cols-2 gap-5">
            <Form.Item<ViewEventType> name={["created_by", "name"]} label="Criado por">
              <Input disabled />
            </Form.Item>

            <Form.Item<ViewEventType> name="created_at" label="Criado em">
              <Input disabled />
            </Form.Item>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <Form.Item<ViewEventType> name={["updated_by", "name"]} label="Atualizado por">
              <Input disabled />
            </Form.Item>
            <Form.Item<ViewEventType> name="updated_at" label="Atualizado em">
              <Input disabled />
            </Form.Item>
          </div>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="billing">
                  <Button
                    type="primary"
                    onClick={() => openDrawer(<UpdateEventForm cuid={cuid} />, "right", 600, "Editar Evento")}
                  >
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
