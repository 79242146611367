import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Select, Space, Spin, Tabs } from "antd";
import { usePermissionGroupsStore } from "../store";
import { CreatePermissionGroupType, SelectCustomerUserItemType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { StandardError } from "@utils/clientHttp";
import { Icon } from "@iconify/react";
import D3PermCheckTable from "@components/shared/D3PermCheckTable/D3PermCheckTable";
import D3Can from "@components/shared/D3Can";
import StatusSelect from "@components/shared/StatusSelect";

export const CreatePermissionGroupForm = () => {
  const { createPermissionGroup, getCustomerUsersForSelect } = usePermissionGroupsStore();
  const { onClose, setSize } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreatePermissionGroupType>();
  const [customerUsers, setCustomerUsers] = useState<SelectCustomerUserItemType[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const result = await getCustomerUsersForSelect();
      setCustomerUsers(result);
      setLoading(false);
    })();
  }, [getCustomerUsersForSelect]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreatePermissionGroupType) => {
            setLoading(true);
            const result = await createPermissionGroup(payload);
            if (result.status === "success") {
              showMessage(result, "Grupo Permissões cadastrado com sucesso.");
              onClose();
            } else {
              showMessage(result as StandardError);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            onTabClick={(tab) => (tab === "1" ? setSize(600) : setSize(1200))}
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<CreatePermissionGroupType>
                      name="name"
                      label="Nome"
                      rules={[{ required: true, message: "Por favor, insira nome." }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item<CreatePermissionGroupType> label="Usuários" name="customer_user_cuids">
                      <Select
                        listItemHeight={10}
                        listHeight={250}
                        allowClear
                        removeIcon={
                          <Icon
                            icon="iconamoon:close-duotone"
                            className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                          />
                        }
                        menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                        optionLabelProp="dataLabel"
                        showSearch
                        maxTagCount="responsive"
                        mode="multiple"
                        size="middle"
                        className="w-full truncate select-none"
                        optionFilterProp="children"
                        filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                      >
                        {customerUsers.map(({ value, active, username, label }) => (
                          <Select.Option
                            key={value}
                            value={value}
                            dataFilter={`${label} ${username}`}
                            dataLabel={`${label}`}
                          >
                            <div className="flex items-center justify-between ">
                              <div className="flex flex-col truncate">
                                <span className="truncate">{label}</span>
                                <span className="text-[10px] truncate">{username}</span>
                              </div>
                              <StatusSelect status={active} />
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "w",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="icon-park-outline:permissions" className="w-[18px] h-[18px]" />
                    Permissões
                  </span>
                ),
                children: (
                  <Form.Item<CreatePermissionGroupType> name="permissions" label="Permissões do grupo">
                    <D3PermCheckTable
                      profile="CTM"
                      onChange={(newValues) => {
                        form.setFieldValue("permissions", newValues);
                      }}
                    />
                  </Form.Item>
                ),
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="customer_permission_group">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
