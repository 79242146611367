import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { App, ConfigProvider } from "antd";
import ptBR from "antd/locale/pt_BR";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import router from "./router";
import React from "react";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  // <React.StrictMode> // Comente se estiver enfrentando problemas com findDOMNode
    <ConfigProvider locale={ptBR}>
      <App className="h-full overflow-hidden">
        <RouterProvider router={router} />
      </App>
    </ConfigProvider>
  // </React.StrictMode>
);

reportWebVitals();
