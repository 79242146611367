import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Select, Space, Spin, Tabs } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { useCustomerUsersStore } from "../store";
import {
  SelectDepartmentItemType,
  SelectPermissionsGroupItemType,
  UpdateCustomerUserType,
  ViewCustomerUserType,
} from "../types";
import { Icon } from "@iconify/react";

import { StandardError } from "@utils/clientHttp";
import D3PermCheckTable from "@components/shared/D3PermCheckTable/D3PermCheckTable";
import D3Can from "@components/shared/D3Can";
import StatusSelect from "@components/shared/StatusSelect";

type UpdateCustomerUserProps = {
  cuid: string;
};

export const UpdateCustomerUserForm = ({ cuid }: UpdateCustomerUserProps) => {
  const { updateCustomerUser, getCustomerUser, getPermissionsGruopForSelect, getDepartmentsForSelect } =
    useCustomerUsersStore();
  const { onClose, setSize } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateCustomerUserType>();
  const [permissiosGruops, setPermissiosGruops] = useState<SelectPermissionsGroupItemType[]>([]);
  const [departments, setdepartments] = useState<SelectDepartmentItemType[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const [getCustomerUserResult, getDepartmentsForSelectResult, getPermissionsGruopForSelectResult] =
        await Promise.all([getCustomerUser(cuid), getDepartmentsForSelect(), getPermissionsGruopForSelect()]);
      if (getCustomerUserResult.status === "success") {
        const viewCustomerUser = getCustomerUserResult.body as ViewCustomerUserType;
        form.setFieldsValue({
          name: viewCustomerUser.name,
          email: viewCustomerUser.email,
          username: viewCustomerUser.username,
          permissions: viewCustomerUser.permissions ?? [],
          customer_permissions_groups_cuids: viewCustomerUser.customer_permissions_groups.map((group) => group.cuid),
          departments_cuids: viewCustomerUser.departments.map((department) => department.cuid),
        });
        setdepartments(getDepartmentsForSelectResult);
        setPermissiosGruops(getPermissionsGruopForSelectResult);
        setLoading(false);
      } else {
        showMessage(getCustomerUserResult);
      }
    })();
  }, [cuid, form, getCustomerUser, getDepartmentsForSelect, getPermissionsGruopForSelect]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: UpdateCustomerUserType) => {
            setLoading(true);
            const payloadUp = {
              ...payload,
              permissions: form.getFieldValue("permissions"),
            };
            const result = await updateCustomerUser(cuid, payloadUp);
            if (result.status === "success") {
              showMessage(result, "Usuário Cliente atualizado com sucesso.");
              onClose();
            } else {
              showMessage(result as StandardError);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            onTabClick={(tab) => (tab === "1" ? setSize(600) : setSize(1200))}
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Formulário
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<UpdateCustomerUserType>
                      name="name"
                      label="Nome"
                      rules={[{ required: true, message: "Por favor, insira o nome." }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item<UpdateCustomerUserType>
                      label="Usuário"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, insira usuário.",
                        },
                        {
                          max: 30,
                          message: "O usuário deve ter menos de 30 caracteres.",
                        },
                        {
                          validator: (_, value) =>
                            /\s/.test(value)
                              ? Promise.reject(new Error("O usuário não pode conter espaços."))
                              : Promise.resolve(),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item<UpdateCustomerUserType>
                      label="E-mail"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, insira o e-mail.",
                        },
                        {
                          type: "email",
                          message: "Por favor, insira um e-mail válido.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item<UpdateCustomerUserType> label="Departamentos" name="departments_cuids">
                      <Select
                        listItemHeight={10}
                        allowClear
                        removeIcon={
                          <Icon
                            icon="iconamoon:close-duotone"
                            className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                          />
                        }
                        menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                        optionLabelProp="dataLabel"
                        showSearch
                        maxTagCount="responsive"
                        mode="multiple"
                        size="middle"
                        className="w-full truncate select-none"
                        optionFilterProp="children"
                        filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                      >
                        {departments.map(({ value, active, label, abbreviation }) => (
                          <Select.Option
                            disabled={!active}
                            key={value}
                            value={value}
                            dataFilter={`${abbreviation} - ${label}`}
                            dataLabel={`${abbreviation} - ${label}`}
                          >
                            <div className="flex items-center justify-between ">
                              <div className="flex flex-col truncate">
                                <span className="truncate">{`${abbreviation} - ${label}`}</span>
                              </div>
                             <StatusSelect status={active} />
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item<UpdateCustomerUserType>
                      label="Grupos de Permissões"
                      name="customer_permissions_groups_cuids"
                    >
                      <Select
                        listItemHeight={10}
                        allowClear
                        removeIcon={
                          <Icon
                            icon="iconamoon:close-duotone"
                            className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                          />
                        }
                        menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                        optionLabelProp="dataLabel"
                        showSearch
                        maxTagCount="responsive"
                        mode="multiple"
                        size="middle"
                        className="w-full truncate select-none"
                        optionFilterProp="children"
                        filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                      >
                        {permissiosGruops.map(({ value, active, label }) => (
                          <Select.Option key={value} value={value} dataFilter={`${label}}`} dataLabel={`${label}`}>
                            <div className="flex items-center justify-between ">
                              <div className="flex flex-col truncate">
                                <span className="truncate">{label}</span>
                              </div>
                              <StatusSelect status={active} />
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "w",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="icon-park-outline:permissions" className="w-[18px] h-[18px]" />
                    Permissões
                  </span>
                ),
                children: (
                  <Form.Item<UpdateCustomerUserType> label="Permissões de usuário" name="permissions">
                    <D3PermCheckTable
                      profile="CTM"
                      onChange={(newValues: string[]) => {
                        form.setFieldValue("permissions", newValues);
                      }}
                    />
                  </Form.Item>
                ),
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer_user">
                  <Button type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
