import { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select, Space, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import { useRepositoriesStore } from "../store";
import {
  SearchRepositoryType,
  SelectCustomerUserItemType,
  SelectDepartmentItemType,
  SelectRepositoryTypeItemType,
} from "../types";
import { Icon } from "@iconify/react";
import { enumSituation } from "@utils/D3RepositorySituation";
import { formatDatePicker } from "@utils/DatePickerFormat";
import StatusSelect from "@components/shared/StatusSelect";

type SearchRepositoryFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchRepositoryForm = ({ onCallBackFilter, onCallBackResetFilter }: SearchRepositoryFormProps) => {
  const [form] = Form.useForm<SearchRepositoryType>();
  const {
    filter,
    setFilter,
    getDepartmentsForSelect,
    getRepositoryTypeForSelectInSearchForm,
    getCustomerUsersForSelect,
  } = useRepositoriesStore();

  useEffect(() => {
    const created = filter.rep_created_at ? dayjs(filter.rep_created_at) : "";
    const updated = filter.rep_updated_at ? dayjs(filter.rep_updated_at) : "";
    form.setFieldsValue({
      ...filter,
      rep_created_at: created,
      rep_updated_at: updated,
    });
  }, [form, filter]);

  const [departments, setDepartments] = useState<SelectDepartmentItemType[]>([]);
  const [repositoryType, setRepositoryType] = useState<SelectRepositoryTypeItemType[]>([]);

  const [customerUsers, setCustomerUsers] = useState<SelectCustomerUserItemType[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const [getDepartmentsForSelectResult, getRepositoryTypeForSelectResult, getCustomerUsersForSelectResult] =
        await Promise.all([
          getDepartmentsForSelect(),
          getRepositoryTypeForSelectInSearchForm(),
          getCustomerUsersForSelect(),
        ]);
      setDepartments(getDepartmentsForSelectResult);
      setRepositoryType(getRepositoryTypeForSelectResult);
      setCustomerUsers(getCustomerUsersForSelectResult);
      setLoading(false);
    })();
  }, [getDepartmentsForSelect, getRepositoryTypeForSelectInSearchForm, getCustomerUsersForSelect]);

  return (
    <Spin spinning={loading}>
      <Form
        className="select-none w-[600px]"
        layout="vertical"
        form={form}
        onFinish={async (payload) => {
          payload.rep_created_at = payload.rep_created_at ? formatDatePicker(payload.rep_created_at) : "";
          payload.rep_updated_at = payload.rep_updated_at ? formatDatePicker(payload.rep_updated_at) : "";
          setFilter(payload);
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Form.Item<SearchRepositoryType> label="Código" name="rep_repository_physical_tag_id">
              <Input />
            </Form.Item>

            <Form.Item<SearchRepositoryType> label="Departamentos" name="dep_cuid">
              <Select
                listItemHeight={10}
                allowClear
                removeIcon={
                  <Icon
                    icon="iconamoon:close-duotone"
                    className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                  />
                }
                menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                optionLabelProp="dataLabel"
                showSearch
                maxTagCount="responsive"
                size="middle"
                className="w-full truncate select-none"
                optionFilterProp="children"
                filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
              >
                {departments.map(({ value, active, label, abbreviation }) => (
                  <Select.Option
                    disabled={!active}
                    key={value}
                    value={value}
                    dataFilter={`${abbreviation} - ${label}`}
                    dataLabel={`${abbreviation} - ${label}`}
                  >
                    <div className="flex items-center justify-between ">
                      <div className="flex flex-col truncate">
                        <span className="truncate">{`${abbreviation} - ${label}`}</span>
                      </div>
                      <StatusSelect status={active} />
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item<SearchRepositoryType> label="Tipo de Repositório" name="rep_type_cuid">
              <Select
                listItemHeight={10}
                allowClear
                removeIcon={
                  <Icon
                    icon="iconamoon:close-duotone"
                    className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                  />
                }
                menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                optionLabelProp="dataLabel"
                showSearch
                maxTagCount="responsive"
                size="middle"
                className="w-full truncate select-none"
                optionFilterProp="children"
                filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
              >
                {repositoryType.map(({ value, label, active }) => (
                  <Select.Option key={value} disabled={!active} value={value} dataFilter={label} dataLabel={label}>
                    <div className="flex items-center justify-between ">
                      <div className="flex flex-col truncate">
                        <span className="truncate">{label}</span>
                      </div>
                      <StatusSelect status={active} />
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item<SearchRepositoryType> name="rep_created_at" label="Criando em">
              <DatePicker format="DD/MM/YYYY" maxDate={dayjs()} />
            </Form.Item>
          </div>
          <div>
            <Form.Item<SearchRepositoryType> label="ID da posição" name="pos_id">
              <Input />
            </Form.Item>

            <Form.Item<SearchRepositoryType> label="Criado por" name="cu_cuid">
              <Select
                listItemHeight={10}
                listHeight={250}
                allowClear
                removeIcon={
                  <Icon
                    icon="iconamoon:close-duotone"
                    className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                  />
                }
                menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                optionLabelProp="dataLabel"
                showSearch
                maxTagCount="responsive"
                size="middle"
                className="w-full truncate select-none"
                optionFilterProp="children"
                filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
              >
                {customerUsers.map(({ value, active, username, label }) => (
                  <Select.Option key={value} value={value} dataFilter={`${label} ${username}`} dataLabel={`${label}`}>
                    <div className="flex items-center justify-between ">
                      <div className="flex flex-col truncate">
                        <span className="truncate">{label}</span>
                        <span className="text-[10px] truncate">{username}</span>
                      </div>
                      <StatusSelect status={active} />
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item<SearchRepositoryType> name="rep_status" label="Situação" className="min-h-16">
              <Select
                options={Array.from(enumSituation, ([value, label]) => ({
                  label,
                  value,
                }))}
              />
            </Form.Item>

            <Form.Item<SearchRepositoryType> name="rep_updated_at" label="Atualizado em">
              <DatePicker format="DD/MM/YYYY" maxDate={dayjs()} />
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
};
