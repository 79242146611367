import { useContext, useEffect, useState } from "react";

import { Button, Form, Input, InputNumber, Radio, Row, Select, Space, Spin, Tooltip } from "antd";

import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { useRepositoriesStore } from "../store";
import {
  SelectDepartmentItemType,
  SelectRepositoryTypeItemType,
  UpdateRepositoryType,
  ViewRepositoryType,
} from "../types";
import StatusSelect from "@components/shared/StatusSelect";

type UpdateRepositoryProps = {
  cuid: string;
};

export const UpdateRepositoryForm = ({ cuid }: UpdateRepositoryProps) => {
  const { getRepository, getDepartmentsForSelect, getRepositoryTypeForSelectInInputForm, updateRepository } =
    useRepositoriesStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateRepositoryType>();
  const [loading, setLoading] = useState(true);

  const [departments, setDepartments] = useState<SelectDepartmentItemType[]>([]);
  const [repositoryType, setRepositoryType] = useState<SelectRepositoryTypeItemType[]>([]);

  const [hasSeals, setHasSeals] = useState<boolean>(false);
  const [hasDepartment, setHasDepartment] = useState<boolean>(false);
  const [fieldCount, setFieldCount] = useState(0);

  useEffect(() => {
    const currentFields = form.getFieldValue("repository_seals") || [];
    const newFields = currentFields.slice(0, fieldCount);

    for (let i = 0; i < fieldCount; i++) {
      newFields[i] = newFields[i] || {};
      newFields[i].number = i + 1;
    }
    form.setFieldsValue({ repository_seals: newFields });
    form.setFieldValue("number_of_seals", fieldCount);
  }, [fieldCount, form]);

  useEffect(() => {
    (async () => {
      const [getRepositoryResult, getDepartmentsForSelectResult] = await Promise.all([
        getRepository(cuid),
        getDepartmentsForSelect(),
      ]);

      if (getRepositoryResult.status === "success") {
        const viewRepository = getRepositoryResult.body as ViewRepositoryType;
        const getReposytoryTypeForSelectResult = await getRepositoryTypeForSelectInInputForm(
          viewRepository.repository_type.cuid
        );

        setHasSeals(form.getFieldValue("has_seals"));
        setDepartments(getDepartmentsForSelectResult);
        setRepositoryType(getReposytoryTypeForSelectResult);

        form.setFieldsValue({
          ...viewRepository,
          billable_department_cuid: viewRepository.billable_department.cuid,
          repository_type_cuid: viewRepository.repository_type.cuid,
        });
      } else {
        showMessage(getRepositoryResult);
      }
      setLoading(false);
    })();
  }, [getDepartmentsForSelect, getRepositoryTypeForSelectInInputForm, getRepository, cuid, form]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: UpdateRepositoryType) => {
            setLoading(true);
            const result = await updateRepository(cuid, payload);
            if (result.status === "success") {
              showMessage(result, "Repositório atualizado com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<UpdateRepositoryType>
            label="Etiqueta de repositório"
            name="repository_physical_tag_id"
            rules={[
              {
                required: true,
                message: "Por favor, insira a etiqueta de repositório.",
              },
              {
                min: 12,
                max: 12,
                message: "A etiqueta do repositório deve incluir uma sequência de 12 dígitos numéricos.",
              },
              {
                validator: (_, value) => {
                  if (value && value !== "") {
                    return /^\d+$/.test(value)
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("A etiqueta do repositório deve ser composta exclusivamente por números.")
                        );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input showCount maxLength={12} minLength={12} />
          </Form.Item>

          <div className="grid grid-cols-3">
            <Form.Item<UpdateRepositoryType> name="global_visibility" label="Visibilidade global?">
              <Radio.Group
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                onChange={(e) => {
                  setHasDepartment(e.target.value);
                  form.setFieldValue("billable_department_cuid", "");
                }}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>

            <Form.Item<UpdateRepositoryType>
              className="col-span-2"
              label="Departamentos"
              name="billable_department_cuid"
              rules={[
                {
                  required: !hasDepartment,
                  message: "Por favor, selecione o departamento.",
                },
              ]}
            >
              <Select
                disabled={hasDepartment}
                listItemHeight={10}
                allowClear
                removeIcon={
                  <Icon
                    icon="iconamoon:close-duotone"
                    className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                  />
                }
                menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                optionLabelProp="dataLabel"
                showSearch
                maxTagCount="responsive"
                size="middle"
                className="w-full truncate select-none"
                optionFilterProp="children"
                filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
              >
                {departments.map(({ value, active, label, abbreviation }) => (
                  <Select.Option
                    disabled={!active}
                    key={value}
                    value={value}
                    dataFilter={`${abbreviation} - ${label}`}
                    dataLabel={`${abbreviation} - ${label}`}
                  >
                    <div className="flex items-center justify-between ">
                      <div className="flex flex-col truncate">
                        <span className="truncate">{`${abbreviation} - ${label}`}</span>
                      </div>
                      <StatusSelect status={active} />
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <Form.Item<UpdateRepositoryType>
            label="Tipo de Repositório"
            name="repository_type_cuid"
            rules={[
              {
                required: true,
                message: "Por favor, selecione o tipo de repositório.",
              },
            ]}
          >
            <Select
              listItemHeight={10}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
              optionLabelProp="dataLabel"
              showSearch
              maxTagCount="responsive"
              size="middle"
              className="w-full truncate select-none"
              optionFilterProp="children"
              filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
            >
              {repositoryType.map(({ value, label, active }) => (
                <Select.Option key={value} disabled={!active} value={value} dataFilter={label} dataLabel={label}>
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{label}</span>
                    </div>
                    <StatusSelect status={active} />
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item<UpdateRepositoryType> label="Origem" name="origin">
            <Input />
          </Form.Item>
          <Row className="flex gap-4">
            <Form.Item<UpdateRepositoryType> name="has_seals" initialValue={hasSeals} label="Possui lacre?">
              <Radio.Group
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                onChange={(e) => {
                  setHasSeals(e.target.value);
                  e.target.value ? setFieldCount(1) : setFieldCount(0);
                }}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
            <Form.Item<UpdateRepositoryType>
              hidden={!hasSeals}
              name="number_of_seals"
              label="Qtd. de lacres"
              rules={[
                {
                  type: "number",
                  pattern: /^[0-9]{1}$/,
                  message: "Insira somente números",
                },
              ]}
            >
              <InputNumber
                type="number"
                min={1}
                max={6}
                value={fieldCount}
                onChange={(value) => {
                  setFieldCount(value || 1);
                }}
              />
            </Form.Item>
          </Row>
          <div className={`${!hasSeals ? "hidden" : ""} w-full`}>
            <Form.List name="repository_seals">
              {(fields, { remove }) => (
                <>
                  {fields.map(({ key, name }, index) => (
                    <Space key={key} align="baseline" className="flex items-center justify-start">
                      <Form.Item
                        className="w-[530px] "
                        label={`${index + 1}º lacre`}
                        name={[name, "value"]}
                        rules={[
                          {
                            required: true,
                            message: `Por favor, insira o ${index + 1}º lacre.`,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Icon
                        onClick={() => {
                          setFieldCount(fieldCount - 1);
                          remove(name);
                        }}
                        icon="material-symbols:delete"
                        height={18}
                        width={18}
                        className="text-red-400 transition-all duration-100 ease-in-out cursor-pointer hover:text-red-500 hover:scale-110"
                      />
                    </Space>
                  ))}
                </>
              )}
            </Form.List>
          </div>

          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer_repository">
                  <Button type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
