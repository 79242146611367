import { useContext, useEffect, useState } from "react";
import { Button, Form, Select, Space, Spin } from "antd";
import { useCompanyStore } from "../../store";
import { MutationAdminType, SelectCompanyUserItemType } from "../../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import StatusSelect from "@components/shared/StatusSelect";

type CreateAdminPropsType = {
  users: string[];
};

export const CreateAdminForm = ({ users }: CreateAdminPropsType) => {
  const { createAdmin, getCompanyUsersForSelect } = useCompanyStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<MutationAdminType>();

  const [companyUsers, setCompanyUsers] = useState<SelectCompanyUserItemType[]>(
    []
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const result = await getCompanyUsersForSelect();
      setCompanyUsers(result.filter((user) => !users.includes(user.value)));
      setLoading(false);
    })();
  }, [getCompanyUsersForSelect, users]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: MutationAdminType) => {
            setLoading(true);
            const result = await createAdmin(payload);
            if (result.status === "success") {
              showMessage(result, "Administrador cadastrado com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<MutationAdminType> label="Usuários" name="users_cuids">
            <Select
              listItemHeight={10}
              listHeight={250}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={
                <Icon
                  icon="eva:checkmark-outline"
                  className="w-5 h-5 text-blue-500"
                />
              }
              optionLabelProp="dataLabel"
              showSearch
              mode="multiple"
              maxTagCount="responsive"
              size="middle"
              className="w-full truncate select-none"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.dataFilter.toLowerCase().includes(input.toLowerCase())
              }
            >
              {companyUsers.map(({ value, active, username, label }) => (
                <Select.Option
                  key={value}
                  value={value}
                  dataFilter={`${label} ${username}`}
                  dataLabel={`${label}`}
                >
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{label}</span>
                      <span className="text-[10px] truncate">{username}</span>
                    </div>
                    <StatusSelect status={active} />
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="system_material">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
