import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, DatePicker, Form, Input, InputNumber, Select, Space, Spin } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useEventsStore } from "../store";
import { CreateEventType, ItemCustomerSystemType, ItemDepartmentsCustomerCuid } from "../types";
import FormItemInputMoney from "@components/shared/FormItemInputMoney";
import StatusSelect from "@components/shared/StatusSelect";

export const CreateEventForm = () => {
  const { createEvent, simpleListCustomerSystem, simpleListDepartmentsByCustomerCuid } = useEventsStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateEventType>();

  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState<ItemCustomerSystemType[]>([]);

  useEffect(() => {
    (async () => {
      setCustomer(await simpleListCustomerSystem());
      setLoading(false);
    })();
  }, [simpleListCustomerSystem]);

  const quantity = Form.useWatch("quantity", form);
  const unitValue = Form.useWatch("unit_value", form);

  useEffect(() => {
    if (quantity && unitValue) {
      form.setFieldValue("value", quantity * unitValue);
    }
  }, [quantity, unitValue, form]);

  const [loadingDepartments, setLoadingDepartments] = useState<boolean>(false);
  const [departments, setDepartments] = useState<ItemDepartmentsCustomerCuid[]>([]);
  async function GetDepartments(cuid: string) {
    setLoadingDepartments(true);
    setDepartments(await simpleListDepartmentsByCustomerCuid(cuid));
    setLoadingDepartments(false);
  }
  /*   const handleValueChange = (_: any, originalValue: any) => {
    const numericValue =
      typeof originalValue === "string" ? parseFloat(originalValue.replace(",", ".")) : originalValue;
    form.setFieldsValue({ unit_value: numericValue });
  }; */

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateEventType) => {
            setLoading(true);
            payload.event_date = dayjs(payload.event_date).format("YYYY-MM-DD");
            const result = await createEvent(payload);
            if (result.status === "success") {
              onClose();
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<CreateEventType>
            name="customer_cuid"
            label="Cliente"
            rules={[{ required: true, message: "Por favor, selecione o cliente." }]}
          >
            <Select
              loading={loading}
              listItemHeight={10}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
              optionLabelProp="dataSelected"
              showSearch
              maxTagCount="responsive"
              size="middle"
              className="w-full truncate select-none"
              optionFilterProp="children"
              onClear={() => {
                form.setFieldValue("department_cuid", null);
                setDepartments([]);
              }}
              onChange={(e) => (e !== undefined ? GetDepartments(e) : null)}
              filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
            >
              {customer.map(({ active, cuid, fantasy_name, id, name, document }) => (
                <Select.Option
                  key={cuid}
                  value={cuid}
                  dataFilter={`${fantasy_name} ${name}  ${id} ${document}`}
                  dataSelected={`${fantasy_name}`}
                >
                  <div className="flex items-center justify-between ">
                    <div className="flex items-center gap-2">
                      <Icon icon="fa6-solid:user" height={12} width={12} />
                      <div className="flex flex-col truncate">
                        <span className="truncate">{fantasy_name}</span>
                        <span className="text-[10px] truncate">{name}</span>
                      </div>
                    </div>
                    <StatusSelect status={active} />
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item<CreateEventType> name="department_cuid" label="Departamento">
            <Select
              listItemHeight={10}
              disabled={loadingDepartments || departments.length === 0}
              loading={loadingDepartments}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
              optionLabelProp="dataLabel"
              showSearch
              maxTagCount="responsive"
              size="middle"
              className="w-full truncate select-none"
              optionFilterProp="children"
              filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
            >
              {departments.map(({ abbreviation, active, cuid, description }) => (
                <Select.Option
                  disabled={!active}
                  key={cuid}
                  value={cuid}
                  dataFilter={`${abbreviation} - ${description}`}
                  dataLabel={`${abbreviation} - ${description}`}
                >
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{`${abbreviation} - ${description}`}</span>
                    </div>
                    <StatusSelect status={active} />
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<CreateEventType>
            name="description"
            label="Descrição"
            rules={[{ required: true, message: "Por favor, insira a descrição." }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<CreateEventType>
            name="quantity"
            label="Quantidade"
            initialValue={1}
            rules={[{ required: true, message: "Por favor, insira a quantidade." }]}
          >
            <InputNumber min={1} />
          </Form.Item>

          <FormItemInputMoney form={form} name="unit_value" label="Valor unitário" required />
          <FormItemInputMoney form={form} name="value" label="Valor total" required />

          <Form.Item<CreateEventType>
            name="event_date"
            label="Data"
            rules={[{ required: true, message: "Por favor, insira data do evento." }]}
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
          <Form.Item<CreateEventType> name="number_of_months" label="Quantidade de meses" initialValue={1}>
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="billing">
                  <Button loading={loading} type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
