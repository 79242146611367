import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Radio, Select, Space, Spin, Table, Tabs } from "antd";
import { useServicesStore } from "../store";
import { CreateServiceType, SelectOperationItemType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { ColumnType, Data } from "../data/data";
import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";
import StatusSelect from "@components/shared/StatusSelect";

type OperationSelected = {
  value: number;
  label: string;
  active: boolean;
  key: number;
};

export const CreateServiceForm = () => {
  const { createService, getOperationsForSelect } = useServicesStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateServiceType>();
  const [loading, setLoading] = useState(true);

  const [operations, setOperations] = useState<SelectOperationItemType[]>([]);
  const [operationsSelected, setOperationsSelected] = useState<OperationSelected[]>([]);

  useEffect(() => {
    (async () => {
      const result = await getOperationsForSelect();
      setOperations(result);
      setLoading(false);
    })();
  }, [getOperationsForSelect]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateServiceType) => {
            setLoading(true);
            const result = await createService(payload);
            if (result.status === "success") {
              showMessage(result, "Serviço cadastrado com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<CreateServiceType>
                      name="description"
                      label="Descrição"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, insira descrição.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "2",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="carbon:settings-services" className="w-5 h-5" />
                    Condições
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<CreateServiceType> label="Condições">
                      <Table
                        className="mb-5 table-service"
                        key="key"
                        size="small"
                        bordered
                        pagination={false}
                        dataSource={Data}
                      >
                        <Table.Column<ColumnType>
                          showSorterTooltip={false}
                          defaultSortOrder="ascend"
                          key="description"
                          title="Descrição"
                          dataIndex="description"
                        />

                        <Table.Column<ColumnType>
                          className="register-service"
                          showSorterTooltip={false}
                          defaultSortOrder="ascend"
                          key="radio"
                          title="Sim ou Não"
                          dataIndex="radio"
                          render={(text, record) => (
                            <Form.Item initialValue={record.radio} name={record.name} className="register-service">
                              <Radio.Group
                                size="small"
                                options={[
                                  { label: "Sim", value: true },
                                  { label: "Não", value: false },
                                ]}
                                value={record.radio}
                                optionType="button"
                                buttonStyle="solid"
                              />
                            </Form.Item>
                          )}
                        />
                      </Table>
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "3",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="carbon:operations-record" className="w-5 h-5" />
                    Operações
                  </span>
                ),
                children: (
                  <>
                    <Form.List name="operations">
                      {(fields, { add, remove }) => {
                        return (
                          <>
                            {fields.map(({ key, name, ...restField }) => {
                              return (
                                <Card
                                  className="mb-2 transition-all duration-200 ease-in border-gray-300 hover:border-gray-400"
                                  key={key}
                                >
                                  <Space style={{ display: "flex" }} align="start">
                                    <Col>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "operation_id"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "É obrigatório selecionar uma Operação ",
                                          },
                                        ]}
                                      >
                                        <Select
                                          listItemHeight={10}
                                          removeIcon={
                                            <Icon
                                              icon="iconamoon:close-duotone"
                                              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                                            />
                                          }
                                          menuItemSelectedIcon={
                                            <Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />
                                          }
                                          optionLabelProp="dataLabel"
                                          showSearch
                                          maxTagCount="responsive"
                                          size="middle"
                                          className="w-full truncate select-none"
                                          optionFilterProp="children"
                                          onSelect={(value, operation) => {
                                            const key = operation.keySelect;
                                            setOperationsSelected(
                                              operationsSelected.filter((operationItem) => operationItem.key !== key)
                                            );
                                            const selectedOperation = operations.find(
                                              (operation) => operation.value === value
                                            );

                                            if (selectedOperation) {
                                              setOperationsSelected((prevOperations) => [
                                                ...prevOperations,
                                                {
                                                  ...selectedOperation,
                                                  key: key,
                                                },
                                              ]);
                                            }
                                          }}
                                          loading={loading}
                                          filterOption={(input, option) =>
                                            option?.dataFilter.toLowerCase().includes(input.toLowerCase())
                                          }
                                        >
                                          {operations.map(({ value, active, label }) => (
                                            <Select.Option
                                              key={value}
                                              value={value}
                                              dataFilter={label}
                                              dataLabel={label}
                                              keySelect={key}
                                              disabled={
                                                operationsSelected.some(
                                                  ({ value: valueSelected }) => value === valueSelected
                                                ) || !active
                                              }
                                            >
                                              <div className="flex items-center justify-between ">
                                                <div className="flex flex-col truncate">
                                                  <span className="truncate">{label}</span>
                                                </div>
                                                <StatusSelect status={active} />
                                              </div>
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "variable"]}
                                        label="A operação permite que seja digitada uma quantidade?"
                                        initialValue={false}
                                      >
                                        <Radio.Group>
                                          <Radio value={true}>Sim</Radio>
                                          <Radio value={false}>Não</Radio>
                                        </Radio.Group>
                                      </Form.Item>

                                      <Form.Item
                                        {...restField}
                                        name={[name, "repository"]}
                                        label="A operação é cobrada uma única vez quando há vários documentos para um mesmo repositório?"
                                        initialValue={false}
                                      >
                                        <Radio.Group value={false}>
                                          <Radio value={true}>Sim</Radio>
                                          <Radio value={false}>Não</Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </Col>
                                    <Col className="mt-2">
                                      <Icon
                                        icon="zondicons:close-outline"
                                        className="w-4 h-4 text-red-400 transition-all duration-200 ease-in hover:text-red-500 hover:scale-110"
                                        onClick={() => {
                                          const operationId = form.getFieldValue(["operations", name, "operation_id"]);
                                          setOperationsSelected(
                                            operationsSelected.filter(
                                              (operationItem) => operationItem.value !== operationId
                                            )
                                          );
                                          remove(name);
                                        }}
                                      />
                                    </Col>
                                  </Space>
                                </Card>
                              );
                            })}
                            <Form.Item>
                              <Button
                                className="flex items-center justify-center"
                                type="dashed"
                                onClick={() => {
                                  add();
                                }}
                                block
                                icon={<Icon icon="ic:round-plus" className="w-5 h-5" />}
                              >
                                Adicionar Operação
                              </Button>
                            </Form.Item>
                          </>
                        );
                      }}
                    </Form.List>
                  </>
                ),
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="system_service">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
