import { Icon } from "@iconify/react";
import useAuthStore from "@provider/AuthProvider";
import { Button, Col, Form, Input, Row, Segmented, Select, Space, Tag } from "antd";
import { useEffect } from "react";
import { useProtocolOrdersStore } from "../store";
import { SearchProtocolOrderType } from "../types";
import { StatusOrders } from "./statusOrders";
import StatusSelect from "@components/shared/StatusSelect";

export const SearchProtocolOrderForm = () => {
  const [form] = Form.useForm<SearchProtocolOrderType>();
  const {
    filter,
    setFilter,
    simpleListCustomer,
    loadingCustomer,
    listCustomer,
    simpleListDepartmentByCustomerCuid,
    loadingDepartments,
    listDepartments,
    resetFilter,
  } = useProtocolOrdersStore();

  const { getUserInfo } = useAuthStore();
  const userInfo = getUserInfo();

  useEffect(() => {
    (async () => {
      await simpleListCustomer();
      if (userInfo.Type === "CTM") {
        await simpleListDepartmentByCustomerCuid(userInfo.CustomerCuid);
        filter.c_cuid = userInfo.CustomerCuid;
      }
    })();
  }, [form, simpleListCustomer]);

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [form, filter]);

  return (
    <>
      <Form
        className="select-none w-[700px]"
        layout="vertical"
        form={form}
        onFinish={async (values: SearchProtocolOrderType) => {
          setFilter(values);
        }}
        autoComplete="off"
        onValuesChange={async (changedValues: any, values: SearchProtocolOrderType) => {
          if (changedValues.c_cuid) {
            await simpleListDepartmentByCustomerCuid(changedValues.c_cuid);
          }
        }}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item<SearchProtocolOrderType> name="po_id" label="Número">
              <Input className="w-full" />
            </Form.Item>
            <Form.Item<SearchProtocolOrderType> name="c_cuid" className="max-h-[58px]" label="Cliente">
              <Select
                disabled={listCustomer.length === 0 || loadingCustomer}
                loading={loadingCustomer}
                listItemHeight={10}
                listHeight={250}
                allowClear
                removeIcon={
                  <Icon
                    icon="iconamoon:close-duotone"
                    className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                  />
                }
                menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                optionLabelProp="dataLabel"
                showSearch
                maxTagCount="responsive"
                size="middle"
                className="w-full truncate select-none"
                optionFilterProp="children"
                onClear={() => {
                  form.setFieldValue("dep_cuid", "");
                  useProtocolOrdersStore.setState({
                    listDepartments: [],
                  });
                }}
                filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
              >
                {listCustomer.map(({ active, cuid, document, fantasy_name, id, name }) => (
                  <Select.Option
                    key={cuid}
                    value={cuid}
                    dataFilter={`${name} ${fantasy_name} ${id} ${document}`}
                    dataLabel={`${name}`}
                  >
                    <div className="flex items-center justify-between ">
                      <div className="flex items-center gap-2">
                        <div className="flex flex-col truncate">
                          <span className="truncate">{fantasy_name}</span>
                          <span className="text-[10px] truncate">{name}</span>
                        </div>
                      </div>
                      <StatusSelect status={active} />
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item<SearchProtocolOrderType> label="Criado por" name="uc_name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item<SearchProtocolOrderType> label="Status" name="po_status" className="max-h-[58px]">
              <Select
                listItemHeight={10}
                listHeight={250}
                allowClear
                removeIcon={
                  <Icon
                    icon="iconamoon:close-duotone"
                    className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                  />
                }
                menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                optionLabelProp="dataLabel"
                showSearch
                maxTagCount="responsive"
                size="middle"
                className="w-full truncate select-none"
                optionFilterProp="children"
                filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
              >
                {StatusOrders.map(({ color, icon, label, status }) => (
                  <Select.Option key={status} value={status} dataFilter={`${status} - ${label}`} dataLabel={`${label}`}>
                    <div className="flex items-center justify-between ">
                      <div className="flex flex-col truncate">
                        <span className="truncate">{label}</span>
                      </div>
                      <span>
                        <Tag
                          className="flex items-center gap-2 py-1 max-w-max"
                          color={color}
                          icon={<Icon icon={icon} />}
                        ></Tag>
                      </span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item<SearchProtocolOrderType> name="dep_cuid" className="max-h-[58px] " label="Departamento">
              <Select
                disabled={listDepartments.length === 0 || loadingDepartments}
                listItemHeight={10}
                allowClear
                className="w-full truncate select-none"
                optionLabelProp="dataLabel"
                showSearch
                loading={loadingDepartments}
                maxTagCount="responsive"
                size="middle"
                optionFilterProp="children"
                filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                removeIcon={
                  <Icon
                    icon="iconamoon:close-duotone"
                    className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                  />
                }
              >
                {listDepartments.map(({ abbreviation, active, cuid, description }) => (
                  <Select.Option
                    key={cuid}
                    value={cuid}
                    dataFilter={`${abbreviation} - ${description}`}
                    dataLabel={`${abbreviation} - ${description}`}
                  >
                    <div className="flex items-center justify-between ">
                      <div className="flex flex-col truncate">
                        <span className="truncate">{`${abbreviation} - ${description}`}</span>
                      </div>
                      <StatusSelect status={active} />
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item<SearchProtocolOrderType> label="Requisitado por" name="ur_name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item<SearchProtocolOrderType> name="po_has_order_resent" label="Possui ordem digitalizada ?">
              <Segmented
                options={[
                  { label: "Sim", value: "on" },
                  { label: "Não", value: "off" },
                  { label: "Todos", value: "all" },
                ]}
                value={filter.po_has_order_resent}
              />
            </Form.Item>
          </Col>
          <Col span={12} className="pt-5">
            <Form.Item>
              <div className="text-right">
                <Space size="small">
                  <Button
                    type="default"
                    danger
                    onClick={() => {
                      resetFilter();
                    }}
                  >
                    Limpar Filtro
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Filtrar
                  </Button>
                </Space>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
