import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { Button, DatePicker, Form, Input, InputNumber, Select, Space, Spin } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useEventsStore } from "../store";
import { ItemDepartmentsCustomerCuid, UpdateEventType, ViewEventType } from "../types";
import FormItemInputMoney from "@components/shared/FormItemInputMoney";
import StatusSelect from "@components/shared/StatusSelect";

type UpdateEventProps = {
  cuid: string;
};

export const UpdateEventForm = ({ cuid }: UpdateEventProps) => {
  const { updateEvent, getEvent, simpleListDepartmentsByCustomerCuid } = useEventsStore();
  const [form] = Form.useForm<UpdateEventType>();
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState<ItemDepartmentsCustomerCuid[]>([]);
  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      const result = await getEvent(cuid);
      if (result.status === "success") {
        const viewEvent = result.body as ViewEventType;
        form.setFieldsValue({
          ...viewEvent,
          event_date: dayjs(viewEvent.event_date),
          department_cuid: viewEvent.department ? viewEvent.department.cuid : "",
        } as unknown as UpdateEventType);
        setDepartments(await simpleListDepartmentsByCustomerCuid(viewEvent.customer.cuid));
      } else {
        showMessage(result);
      }
      setLoading(false);
    })();
  }, [cuid, form, getEvent, simpleListDepartmentsByCustomerCuid]);

  const quantity = Form.useWatch("quantity", form);
  const unitValue = Form.useWatch("unit_value", form);

  useEffect(() => {
    form.setFieldValue("value", quantity * unitValue);
  }, [quantity, unitValue, form]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (payload: UpdateEventType) => {
            setLoading(true);
            payload.event_date = dayjs(payload.event_date).format("YYYY-MM-DD");
            const result = await updateEvent(cuid, payload);
            if (result.status === "success") {
              onClose();
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item name={["customer", "fantasy_name"]} label="Cliente">
            <Input disabled />
          </Form.Item>
          <Form.Item<UpdateEventType> name="department_cuid" label="Departamento">
            <Select
              listItemHeight={10}
              disabled={loading || departments.length === 0}
              loading={loading}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
              optionLabelProp="dataLabel"
              showSearch
              maxTagCount="responsive"
              size="middle"
              className="w-full truncate select-none"
              optionFilterProp="children"
              filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
            >
              {departments.map(({ abbreviation, active, cuid, description }) => (
                <Select.Option
                  disabled={!active}
                  key={cuid}
                  value={cuid}
                  dataFilter={`${abbreviation} - ${description}`}
                  dataLabel={`${abbreviation} - ${description}`}
                >
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{`${abbreviation} - ${description}`}</span>
                    </div>
                   <StatusSelect status={active} />
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<UpdateEventType>
            name="description"
            label="Descrição"
            rules={[{ required: true, message: "Por favor, insira a descrição." }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<UpdateEventType>
            name="quantity"
            label="Quantidade"
            initialValue={1}
            rules={[{ required: true, message: "Por favor, insira a quantidade." }]}
          >
            <InputNumber min={1} />
          </Form.Item>

          <FormItemInputMoney form={form} name="unit_value" label="Valor unitário" required />
          <FormItemInputMoney form={form} name="value" label="Valor total" required />

          <Form.Item<UpdateEventType>
            name="event_date"
            label="Data"
            rules={[{ required: true, message: "Por favor, insira data do evento." }]}
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="billing">
                  <Button type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
