import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { TypeOfRequest } from "@views/orders/Utils";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useMaterialOrdersStore } from "../store";
import {
  AddressItemType,
  CreateMaterialOrderType,
  ItemSimpleListDepartmentType,
  MaterialItemType,
  RequestedMaterialType,
  UserDepartmentItemType,
} from "../types";
import StatusSelect from "@components/shared/StatusSelect";

const { TextArea } = Input;

export const CreateMaterialOrderForm = () => {
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateMaterialOrderType>();
  const {
    createMaterialOrder,
    getDepartmentsForSelect,
    getUsersDepartmentForSelect,
    getAddressForSelect,
    getMaterialsForSelect,
  } = useMaterialOrdersStore();

  const [departments, setDepartments] = useState<ItemSimpleListDepartmentType[]>([]);
  const [usersDepartment, setUsersDepartment] = useState<UserDepartmentItemType[]>([]);
  const [address, setAddress] = useState<AddressItemType[]>([]);
  const [materials, setMaterials] = useState<MaterialItemType[]>([]);
  const [listMaterials, setListMaterials] = useState<RequestedMaterialType[]>([]);

  const [materialSelected, setMaterialSelected] = useState<string>();
  const [quantity, setQuantity] = useState<number | undefined>();

  const [haveTransport, setHaveTransport] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const [departmentsResult, addressResult, materialsResult] = await Promise.all([
        getDepartmentsForSelect(),
        getAddressForSelect(),
        getMaterialsForSelect(),
      ]);
      setDepartments(departmentsResult);
      setAddress(addressResult);
      setMaterials(materialsResult);
      setLoading(false);
    })();
  }, [getDepartmentsForSelect, getAddressForSelect, getMaterialsForSelect]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateMaterialOrderType) => {
            if (listMaterials.length < 1) {
              notification.warning({
                message: "Atenção!",
                description: "Adicione materiais para cadastrar a ordem.",
              });
            } else {
              setLoading(true);
              payload.requested_materials = listMaterials;
              payload.observation = payload.observation ? payload.observation.trim() : "";
              const result = await createMaterialOrder(payload);
              if (result.status === "success") {
                showMessage(result, "Ordem de material cadastrada com sucesso.");
                onClose();
              } else {
                showMessage(result);
              }
              setLoading(false);
            }
          }}
          onValuesChange={async (changedValues: any, values: CreateMaterialOrderType) => {
            if (changedValues.department_cuid) {
              form.setFieldValue("requester_cuid", null);
              setUsersDepartment([]);
              setLoadingUsers(true);
              const usersDepartment = await getUsersDepartmentForSelect(changedValues.department_cuid);
              setUsersDepartment(usersDepartment);
              setLoadingUsers(false);
            }
          }}
          autoComplete="off"
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item<CreateMaterialOrderType>
                name="department_cuid"
                label="Departamento"
                rules={[{ required: true, message: "Por favor, selecione departamento." }]}
              >
                <Select
                  listItemHeight={10}
                  allowClear
                  removeIcon={
                    <Icon
                      icon="iconamoon:close-duotone"
                      className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                    />
                  }
                  menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                  optionLabelProp="dataLabel"
                  showSearch
                  maxTagCount="responsive"
                  size="middle"
                  className="w-full truncate select-none"
                  optionFilterProp="children"
                  onClear={() => {
                    form.setFieldValue("requester_cuid", null);
                    setUsersDepartment([]);
                  }}
                  filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                >
                  {departments.map(({ abbreviation, active, cuid, description }) => (
                    <Select.Option
                      disabled={!active}
                      key={cuid}
                      value={cuid}
                      dataFilter={`${abbreviation} - ${description}`}
                      dataLabel={`${abbreviation} - ${description}`}
                    >
                      <div className="flex items-center justify-between ">
                        <div className="flex flex-col truncate">
                          <span className="truncate">{`${abbreviation} - ${description}`}</span>
                        </div>
                         <StatusSelect status={active} />
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item<CreateMaterialOrderType> label="Usuários" name="requester_cuid">
                <Select
                  disabled={usersDepartment.length < 1}
                  loading={loadingUsers}
                  listItemHeight={10}
                  listHeight={250}
                  allowClear
                  removeIcon={
                    <Icon
                      icon="iconamoon:close-duotone"
                      className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                    />
                  }
                  menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                  optionLabelProp="dataLabel"
                  showSearch
                  maxTagCount="responsive"
                  size="middle"
                  className="w-full truncate select-none"
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                >
                  {usersDepartment.map(({ active, cuid, login, name, username }) => (
                    <Select.Option
                      disabled={!active}
                      key={cuid}
                      value={cuid}
                      dataFilter={`${name} ${username}`}
                      dataLabel={`${name}`}
                    >
                      <div className="flex items-center justify-between ">
                        <div className="flex flex-col truncate">
                          <span className="truncate">{name}</span>
                          <span className="text-[10px] truncate">{username}</span>
                        </div>
                        <StatusSelect status={active} />
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item<CreateMaterialOrderType>
                label="Tipo de requisição"
                name="request_type"
                rules={[
                  {
                    required: true,
                    message: "Por favor, selecione o tipo de requisição.",
                  },
                ]}
              >
                <Select
                  listItemHeight={10}
                  listHeight={250}
                  allowClear
                  removeIcon={
                    <Icon
                      icon="iconamoon:close-duotone"
                      className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                    />
                  }
                  menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                  optionLabelProp="dataLabel"
                  showSearch
                  maxTagCount="responsive"
                  size="middle"
                  className="w-full truncate select-none"
                  optionFilterProp="children"
                  onChange={(value) => {
                    if (value === "WOT" || value === undefined || value === "") {
                      form.setFieldValue("address_cuid", undefined);
                      setHaveTransport(true);
                    } else {
                      setHaveTransport(false);
                    }
                  }}
                  filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                >
                  {TypeOfRequest.map(({ label, status, color, icon }) => (
                    <Select.Option
                      key={status}
                      value={status}
                      dataFilter={`${status} - ${label}`}
                      dataLabel={`${label}`}
                    >
                      <div className="flex items-center justify-between ">
                        <div className="flex flex-col truncate">
                          <span className="truncate">{label}</span>
                        </div>
                        <span>
                          <Tag
                            className="flex items-center gap-2 py-1 text-red-700 max-w-max"
                            color={color}
                            icon={<Icon height={16} icon={icon} />}
                          ></Tag>
                        </span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item<CreateMaterialOrderType>
                label="Endereço"
                name="address_cuid"
                rules={[
                  {
                    required: !haveTransport && form.getFieldValue("request_type") !== undefined,
                    message: "Por favor, selecione o endereço.",
                  },
                ]}
              >
                <Select
                  listItemHeight={10}
                  listHeight={250}
                  disabled={haveTransport}
                  allowClear
                  removeIcon={
                    <Icon
                      icon="iconamoon:close-duotone"
                      className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                    />
                  }
                  menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                  optionLabelProp="dataLabel"
                  showSearch
                  maxTagCount="responsive"
                  size="middle"
                  className="w-full truncate select-none"
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                >
                  {address.map(
                    ({
                      city,
                      complement,
                      cuid,
                      description,
                      state,
                      street_address,
                      street_number,
                      zip_code,
                      active,
                      is_default,
                    }) => (
                      <Select.Option
                        key={cuid}
                        value={cuid}
                        dataFilter={`${description} ${address}`}
                        dataLabel={`${description}`}
                      >
                        <div className="flex items-center justify-between ">
                          <div className="flex flex-col truncate">
                            <span className="truncate">{description}</span>
                            <span className="text-[10px] truncate">{`${street_address}, nº ${street_number}${
                              complement ? ", " + complement : ""
                            }, ${city} - ${state}, Cep ${zip_code}`}</span>
                          </div>
                          <div className="flex items-center justify-between gap-2">
                            {is_default && (
                              <Tooltip title="Endereço Padrão" placement="right">
                                <Tag
                                  className="flex items-center justify-between gap-1"
                                  icon={<Icon icon="bi:geo-alt-fill" />}
                                  color="success"
                                >
                                  Padrão
                                </Tag>
                              </Tooltip>
                            )}
                            <StatusSelect status={active} />
                          </div>
                        </div>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
              <Form.Item<CreateMaterialOrderType> label="Observação" name="observation">
                <TextArea autoSize={{ minRows: 5 }} maxLength={5000} showCount />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row gutter={15}>
                <Col span={18}>
                  <Form.Item label="Selecione o material">
                    <Select
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                      listItemHeight={10}
                      allowClear
                      removeIcon={
                        <Icon
                          icon="iconamoon:close-duotone"
                          className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                        />
                      }
                      value={materialSelected}
                      menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                      optionLabelProp="dataLabel"
                      showSearch
                      maxTagCount="responsive"
                      size="middle"
                      className="w-full truncate select-none"
                      optionFilterProp="children"
                      onChange={(value) => setMaterialSelected(value)}
                      filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                    >
                      {materials
                        .filter(({ cuid }) => !listMaterials.some((item) => item.material_cuid === cuid))
                        .map(({ cuid, description }) => (
                          <Select.Option key={cuid} value={cuid} dataFilter={description} dataLabel={description}>
                            <div className="flex items-center ">
                              <div className="flex flex-col truncate">
                                <span className="truncate">{description}</span>
                              </div>
                            </div>
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Quantidade">
                    <InputNumber
                      value={quantity}
                      onChange={(value) => setQuantity(value as number)}
                      className="w-full"
                      min={1}
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Tooltip title="Adicionar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                    <Button
                      onClick={() => {
                        if (materialSelected && quantity) {
                          setListMaterials([
                            ...listMaterials,
                            {
                              material_cuid: materialSelected,
                              quantity: quantity,
                            },
                          ]);
                          setMaterialSelected(undefined);
                          setQuantity(undefined);
                        } else {
                          notification.error({
                            message: "Atenção!",
                            description:
                              !materialSelected && !quantity
                                ? "Selecione o material e adicione a quantidade."
                                : !materialSelected
                                ? "Selecione o material"
                                : !quantity
                                ? "Adicione a quantidade."
                                : "",
                          });
                        }
                      }}
                      className="flex items-center justify-center mt-6"
                      type="primary"
                      icon={
                        <Icon
                          icon="ic:round-plus"
                          height={18}
                          width={18}
                          className="text-white transition-all duration-100 ease-in hover:text-blue-200"
                        />
                      }
                      size={"middle"}
                    />
                  </Tooltip>
                </Col>
              </Row>

              <Form.Item label="Materiais requisitados">
                <Table
                  bordered
                  size="small"
                  sticky
                  dataSource={listMaterials}
                  rowKey="material_cuid"
                  pagination={false}
                >
                  <Table.Column
                    showSorterTooltip={false}
                    key="material_cuid"
                    title="Material"
                    dataIndex="material_cuid"
                    render={(value) => {
                      const material = materials.find((mat) => mat.cuid === value);
                      return material?.description;
                    }}
                  />
                  <Table.Column
                    showSorterTooltip={false}
                    key="quantity"
                    title="Quantidade"
                    dataIndex="quantity"
                    render={(value, data: RequestedMaterialType) => (
                      <InputNumber
                        min={1}
                        onChange={(e) => {
                          const updatedListMaterials = listMaterials.map((item) => {
                            if (data.material_cuid === item.material_cuid) {
                              return { ...item, quantity: e };
                            } else {
                              return item;
                            }
                          });
                          setListMaterials(updatedListMaterials);
                        }}
                        value={value}
                      />
                    )}
                  />
                  <Table.Column
                    key="actions"
                    title="Ações"
                    width={80}
                    align="center"
                    render={(value: RequestedMaterialType) => (
                      <div className="flex items-center justify-center">
                        <Tooltip title="Delete" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                          <Button
                            onClick={() =>
                              setListMaterials(
                                listMaterials.filter((item) => item.material_cuid !== value.material_cuid)
                              )
                            }
                            className="flex items-center justify-center"
                            type="text"
                            shape="circle"
                            icon={
                              <Icon
                                icon="material-symbols:delete"
                                height={18}
                                width={18}
                                className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                              />
                            }
                            size={"middle"}
                          />
                        </Tooltip>
                      </div>
                    )}
                  />
                </Table>

                <Table.Column
                  showSorterTooltip={false}
                  key="delete"
                  title="Ações"
                  dataIndex="delete"
                  render={(value) => (
                    <Tooltip title="Delete" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                      <Button
                        className=""
                        type="text"
                        shape="circle"
                        icon={
                          <Icon
                            icon="material-symbols:delete"
                            height={18}
                            width={18}
                            className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                          />
                        }
                        size={"middle"}
                      />
                    </Tooltip>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="order_material">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
