import { Icon } from "@iconify/react";
import { Tooltip } from "antd";

type StatusSelectProps = {
  status: boolean;
};

function StatusSelect({ status }: StatusSelectProps) {
  return status ? (
    <Tooltip title="Desbloqueado" placement="right">
      <span>
        <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
      </span>
    </Tooltip>
  ) : (
    <Tooltip title="Bloqueado" placement="right">
      <span>
        <Icon icon="basil:lock-solid" className="text-red-500 " height={16} width={16} />
      </span>
    </Tooltip>
  );
}

export default StatusSelect;
