import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { TypeOfRequest } from "@views/orders/Utils";
import { Badge, Button, Form, Input, Pagination, Select, Space, Table, Tag, Tooltip, notification } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDeploymentOrdersStore } from "../store";
import {
  AddressItemType,
  CreateDeploymentOrderType,
  ItemSimpleListDepartmentType,
  ItemSimpleListRepositoryType,
  RepositoryItemType,
} from "../types";
import StatusSelect from "@components/shared/StatusSelect";

const { Search } = Input;

enum Status {
  STO = "Armazenado",
  REG = "Cadastrado",
  PUR = "Expurgado",
  DEL = "Deletado",
  MIG = "Em migração",
  REQ = "Solicitado",
  PRO = "Em processamento",
  INC = "No cliente",
}
export const CreateDeploymentOrderForm = () => {
  const [form] = Form.useForm<CreateDeploymentOrderType>();
  const {
    createDeploymentOrder,
    simpleListAddress,
    simpleListDepartment,
    simpleListRepositoryType,
    apiRepositories,
    getRepositories,
    setRepositoriesPagination,
    repositoriesPagination,
    apiRepositoriesLoading,
    selectedRows,
    resetStateCreate,
    resetRepositories,
    filterRepositories,
    payloadRepositories,
    loadingCreated,
  } = useDeploymentOrdersStore();

  const navigate = useNavigate();
  const [departments, setDepartments] = useState<ItemSimpleListDepartmentType[]>([]);
  const [repositoryTypes, setRepositoryTypes] = useState<ItemSimpleListRepositoryType[]>([]);
  const [haveTransport, setHaveTransport] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [showSelected, setShowSelected] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const [departmentsResult, RepositoryTypeResult] = await Promise.all([
        simpleListDepartment(),
        simpleListRepositoryType(),
      ]);
      setDepartments(departmentsResult);
      setRepositoryTypes(RepositoryTypeResult);
      setLoading(false);
    })();
    return () => resetStateCreate();
  }, [simpleListDepartment, simpleListRepositoryType, resetStateCreate]);

  const [address, setAddress] = useState<AddressItemType[]>([]);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const handleTypeRequest = async () => {
    setAddress(await simpleListAddress());
    setLoadingAddress(false);
  };

  const departmentCuid = Form.useWatch("department_cuid", form);
  const repositoryTypeCuid = Form.useWatch("repository_type_cuid", form);
  const handleFilter = async () => {
    await getRepositories({ department_cuid: departmentCuid, repository_type_cuid: repositoryTypeCuid });
  };

  return (
    <div className="flex h-full mb-1 mr-1 overflow-hidden bg-white rounded shadow">
      <Form
        className="flex w-full h-full select-none"
        form={form}
        layout="vertical"
        onFinish={async (payload: CreateDeploymentOrderType) => {
          if (selectedRows.length > 0) {
            payload.repositories_ids = selectedRows;
            const isCreated = await createDeploymentOrder(payload);
            if (isCreated) {
              navigate(-1);
            }
          } else {
            notification.error({
              message: "Atenção !",
              description: "Para finalizar o processo, selecione pelo menos um repositório.",
            });
          }
        }}
        autoComplete="off"
      >
        <div className="flex flex-col w-full h-full overflow-hidden">
          <div className="grid grid-cols-3 items-center justify-center w-full h-[55px] border-b border-gray-200 min-h-[55px]">
            <div className="flex justify-start px-2">
              <Button
                className="flex items-center justify-center"
                icon={<Icon height={18} icon="ion:arrow-back-outline" />}
                type="dashed"
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
            </div>
            <div className="flex justify-center">
              <span className="text-xl font-semibold text-gray-600">Cadastrar Ordem de Implantação</span>
            </div>
            <div></div>
          </div>

          <div className="grid w-full h-full grid-cols-5 overflow-hidden divide-x">
            <div className="col-span-1 p-3">
              <Form.Item<CreateDeploymentOrderType>
                name="repository_type_cuid"
                label="Tipos de repositório"
                rules={[{ required: true, message: "Por favor, selecione o tipo de repositório." }]}
              >
                <Select
                  loading={loading}
                  disabled={loading}
                  listItemHeight={10}
                  allowClear
                  removeIcon={
                    <Icon
                      icon="iconamoon:close-duotone"
                      className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                    />
                  }
                  menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                  optionLabelProp="dataLabel"
                  showSearch
                  maxTagCount="responsive"
                  size="middle"
                  className="w-full truncate select-none"
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                  onClear={() => resetRepositories()}
                  onChange={() => resetRepositories()}
                >
                  {repositoryTypes.map(({ active, cuid, description }) => (
                    <Select.Option
                      disabled={!active}
                      key={cuid}
                      value={cuid}
                      dataFilter={description}
                      dataLabel={description}
                    >
                      <div className="flex items-center justify-between ">
                        <div className="flex flex-col truncate">
                          <span className="truncate">{description}</span>
                        </div>
                        <StatusSelect status={active} />
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item<CreateDeploymentOrderType>
                name="department_cuid"
                label="Departamento"
                rules={[{ required: true, message: "Por favor, selecione departamento." }]}
              >
                <Select
                  loading={loading}
                  disabled={loading}
                  listItemHeight={10}
                  allowClear
                  removeIcon={
                    <Icon
                      icon="iconamoon:close-duotone"
                      className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                    />
                  }
                  menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                  optionLabelProp="dataLabel"
                  showSearch
                  maxTagCount="responsive"
                  size="middle"
                  className="w-full truncate select-none"
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                  onClear={() => resetRepositories()}
                  onChange={() => resetRepositories()}
                >
                  {departments.map(({ abbreviation, active, cuid, description }) => (
                    <Select.Option
                      disabled={!active}
                      key={cuid}
                      value={cuid}
                      dataFilter={`${abbreviation} - ${description}`}
                      dataLabel={`${abbreviation} - ${description}`}
                    >
                      <div className="flex items-center justify-between ">
                        <div className="flex flex-col truncate">
                          <span className="truncate">{`${abbreviation} - ${description}`}</span>
                        </div>
                        <StatusSelect status={active} />
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item<CreateDeploymentOrderType>
                label="Tipo de requisição"
                name="request_type"
                rules={[
                  {
                    required: true,
                    message: "Por favor, selecione o tipo de requisição.",
                  },
                ]}
              >
                <Select
                  listItemHeight={10}
                  listHeight={250}
                  allowClear
                  removeIcon={
                    <Icon
                      icon="iconamoon:close-duotone"
                      className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                    />
                  }
                  menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                  optionLabelProp="dataLabel"
                  showSearch
                  maxTagCount="responsive"
                  size="middle"
                  className="w-full truncate select-none"
                  optionFilterProp="children"
                  onChange={(value) => {
                    if (value === "WOT" || value === undefined || value === "") {
                      form.setFieldValue("address_cuid", undefined);
                      setHaveTransport(true);
                    } else {
                      if (address.length === 0) {
                        setLoadingAddress(true);
                        handleTypeRequest();
                      }
                      setHaveTransport(false);
                    }
                  }}
                  filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                >
                  {TypeOfRequest.map(({ label, status, color, icon }) => (
                    <Select.Option
                      key={status}
                      value={status}
                      dataFilter={`${status} - ${label}`}
                      dataLabel={`${label}`}
                    >
                      <div className="flex items-center justify-between ">
                        <div className="flex flex-col truncate">
                          <span className="truncate">{label}</span>
                        </div>
                        <span>
                          <Tag
                            className="flex items-center gap-2 py-1 text-red-700 max-w-max"
                            color={color}
                            icon={<Icon height={16} icon={icon} />}
                          ></Tag>
                        </span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item<CreateDeploymentOrderType>
                label="Endereço"
                name="address_cuid"
                rules={[
                  {
                    required: !haveTransport && form.getFieldValue("request_type") !== undefined,
                    message: "Por favor, selecione o endereço.",
                  },
                ]}
              >
                <Select
                  loading={loadingAddress}
                  listItemHeight={10}
                  listHeight={250}
                  disabled={haveTransport || loadingAddress}
                  allowClear
                  removeIcon={
                    <Icon
                      icon="iconamoon:close-duotone"
                      className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                    />
                  }
                  menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                  optionLabelProp="dataLabel"
                  showSearch
                  maxTagCount="responsive"
                  size="middle"
                  className="w-full truncate select-none"
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                >
                  {address.map(
                    ({
                      city,
                      complement,
                      cuid,
                      description,
                      state,
                      street_address,
                      street_number,
                      zip_code,
                      active,
                      is_default,
                    }) => (
                      <Select.Option
                        key={cuid}
                        value={cuid}
                        dataFilter={`${description} ${address}`}
                        dataLabel={`${description}`}
                      >
                        <div className="flex items-center justify-between ">
                          <div className="flex flex-col truncate">
                            <span className="truncate">{description}</span>
                            <span className="text-[10px] truncate">{`${street_address}, nº ${street_number}${
                              complement ? ", " + complement : ""
                            }, ${city} - ${state}, Cep ${zip_code}`}</span>
                          </div>
                          <div className="flex items-center justify-between gap-2">
                            {is_default && (
                              <Tooltip title="Endereço Padrão" placement="right">
                                <Tag
                                  className="flex items-center justify-between gap-1"
                                  icon={<Icon icon="bi:geo-alt-fill" />}
                                  color="success"
                                >
                                  Padrão
                                </Tag>
                              </Tooltip>
                            )}
                            <StatusSelect status={active} />
                          </div>
                        </div>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
              <Form.Item>
                <div className="text-right">
                  <Space size="small">
                    <Button
                      type="default"
                      danger
                      onClick={() => {
                        useDeploymentOrdersStore.setState({
                          selectedRows: [],
                          apiRepositories: {
                            data: [],
                            total: 0,
                          },
                          filterRepositories: {
                            rep_repository_physical_tag_id: "",
                          },
                          payloadRepositories: {
                            department_cuid: "",
                            repository_type_cuid: "",
                          },
                        });
                        form.resetFields();
                      }}
                    >
                      Limpar filtro
                    </Button>

                    <Button
                      disabled={departmentCuid && repositoryTypeCuid ? false : true}
                      type="primary"
                      onClick={handleFilter}
                    >
                      Filtrar
                    </Button>
                  </Space>
                </div>
              </Form.Item>
            </div>
            <div className="h-full col-span-4 p-3 overflow-hidden">
              <Table
                rowKey="id"
                title={() => (
                  <div className="grid items-center w-full grid-cols-3 ">
                    <div>
                      <Search
                        disabled={apiRepositories.total === 0}
                        allowClear
                        className="w-64"
                        maxLength={12}
                        showCount
                        value={filterRepositories.rep_repository_physical_tag_id}
                        placeholder="código da etiqueta"
                        onChange={(e) => {
                          useDeploymentOrdersStore.setState({
                            filterRepositories: {
                              rep_repository_physical_tag_id: e.target.value,
                            },
                          });
                        }}
                        loading={apiRepositoriesLoading}
                        onSearch={async (value) => {
                          if (value.length === 12) {
                            await getRepositories(payloadRepositories);
                          } else {
                            notification.info({
                              message: "Atenção!",
                              description: "A etiqueta deve conter exatamente 12 dígitos numéricos.",
                            });
                          }
                        }}
                        enterButton
                        onPressEnter={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="flex items-center justify-center">
                      <span className="text-base font-semibold text-gray-600">Lista de Repositórios</span>
                    </div>
                    <div className="flex justify-end pr-3 mt-1">
                      <Badge size="small" count={showSelected ? apiRepositories.data.length : selectedRows.length}>
                        <Button
                          onClick={() => setShowSelected(!showSelected)}
                          disabled={selectedRows.length === 0 && !showSelected}
                          type="primary"
                        >
                          {`Exibir ${
                            showSelected ? "todos" : selectedRows.length > 1 ? "selecionados" : "selecionado"
                          }`}
                        </Button>
                      </Badge>
                    </div>
                  </div>
                )}
                footer={() => (
                  <div className="flex items-center justify-between w-full">
                    <Badge size="small" count={selectedRows.length}>
                      <Button
                        onClick={() =>
                          useDeploymentOrdersStore.setState({
                            selectedRows: [],
                          })
                        }
                        danger
                        disabled={selectedRows.length === 0}
                        type="dashed"
                      >
                        Remover todos selecionados
                      </Button>
                    </Badge>

                    <Pagination
                      disabled={apiRepositories.total === 0}
                      showLessItems
                      showTotal={(total, range) =>
                        `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${
                          total > 1 ? "registros" : "registro"
                        }`
                      }
                      showSizeChanger={true}
                      current={repositoriesPagination.page}
                      total={apiRepositories.total}
                      pageSize={repositoriesPagination.pageSize}
                      onChange={(page, pageSize) => setRepositoriesPagination(page, pageSize)}
                    />
                  </div>
                )}
                className="d3-table-h-full h-footer"
                pagination={false}
                loading={apiRepositoriesLoading}
                bordered
                size="small"
                dataSource={
                  showSelected
                    ? apiRepositories.data.filter((repo) => selectedRows.includes(repo.id))
                    : apiRepositories.data
                }
                rowSelection={{
                  hideSelectAll: false,
                  type: "checkbox",
                  selectedRowKeys: selectedRows,
                  onChange: (_, selectRows: RepositoryItemType[]) => {
                    useDeploymentOrdersStore.setState({
                      selectedRows: selectRows.map((repo) => repo.id),
                    });
                  },
                }}
              >
                <Table.Column<RepositoryItemType>
                  showSorterTooltip={false}
                  key="repository_physical_tag_id"
                  title="Código da etiqueta"
                  dataIndex="repository_physical_tag_id"
                />
                <Table.Column<RepositoryItemType>
                  showSorterTooltip={false}
                  key="billable_department"
                  title="Departamento"
                  dataIndex={["billable_department", "description"]}
                />
                <Table.Column<RepositoryItemType>
                  showSorterTooltip={false}
                  key="repository_type"
                  title="Tipo de repositório"
                  dataIndex={["repository_type", "description"]}
                />
                <Table.Column<RepositoryItemType>
                  showSorterTooltip={false}
                  key="number_of_documents"
                  title="Qtd. documentos"
                  dataIndex="number_of_documents"
                />
                <Table.Column<RepositoryItemType>
                  showSorterTooltip={false}
                  key="created_by"
                  title="Criado por"
                  dataIndex="created_by"
                />
                <Table.Column<RepositoryItemType>
                  showSorterTooltip={false}
                  key="created_at"
                  title="Criado em"
                  dataIndex="created_at"
                  render={(value, record) => D3DateHourSorter(record.created_at)}
                />
                <Table.Column<RepositoryItemType>
                  showSorterTooltip={false}
                  key="status"
                  title="Status"
                  dataIndex="status"
                  render={(value, record) => <span>{Status[record.status as keyof typeof Status]}</span>}
                />
              </Table>
            </div>
          </div>
          <div className="flex items-center justify-end w-full px-2 border-t h-[55px] min-h-[55px] border-gray-200 ">
            <Form.Item className="p-0 m-0">
              <Space size="small">
                <Button disabled={loadingCreated} type="default" danger onClick={() => navigate(-1)}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="order_deployment">
                  <Button loading={loadingCreated} type="primary" htmlType="submit">
                    Finalizar Requisição de Implantação
                  </Button>
                </D3Can>
              </Space>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};
