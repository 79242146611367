import { Icon } from "@iconify/react";
import { Button, DatePicker, Form, Input, Select, Space } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useEventsStore } from "../store";
import { ItemCustomerSystemType, SearchEventType } from "../types";
import StatusSelect from "@components/shared/StatusSelect";

type SearchEventFormProps = {
	onCallBackFilter: () => void;
	onCallBackResetFilter: () => void;
};

export const SearchEventForm = ({ onCallBackFilter, onCallBackResetFilter }: SearchEventFormProps) => {
	const [form] = Form.useForm<SearchEventType>();
	const { filter, setFilter, simpleListCustomerSystem } = useEventsStore();
	const [loading, setLoading] = useState(true);
	const [customer, setCustomer] = useState<ItemCustomerSystemType[]>([]);

	useEffect(() => {
		(async () => {
			setCustomer(await simpleListCustomerSystem());
		})();
		form.setFieldsValue({
			...filter,
			event_date: filter.event_date ? dayjs(filter.event_date) : "",
		});
		setLoading(false);
	}, [form, filter, simpleListCustomerSystem]);

	return (
		<>
			<Form
				className="select-none w-96"
				layout="vertical"
				form={form}
				onFinish={async (payload) => {
					payload.event_date = payload.event_date ? dayjs(payload.event_date).format("YYYY-MM-DD") : "";
					setFilter(payload);
					onCallBackFilter();
				}}
				autoComplete="off"
			>
				<Form.Item<SearchEventType> name="c_cuid" label="Cliente">
					<Select
						listItemHeight={10}
						allowClear
						removeIcon={
							<Icon icon="iconamoon:close-duotone" className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600" />
						}
						loading={loading}
						disabled={loading || customer.length === 0}
						menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
						optionLabelProp="dataSelected"
						showSearch
						maxTagCount="responsive"
						size="middle"
						className="w-full truncate select-none"
						optionFilterProp="children"
						filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
					>
						{customer.map(({ active, cuid, fantasy_name, id, name, document }) => (
							<Select.Option key={cuid} value={cuid} dataFilter={`${fantasy_name} ${name}  ${id} ${document}`} dataSelected={`${fantasy_name}`}>
								<div className="flex items-center justify-between ">
									<div className="flex items-center gap-2">
										<Icon icon="fa6-solid:user" height={12} width={12} />
										<div className="flex flex-col truncate">
											<span className="truncate">{fantasy_name}</span>
											<span className="text-[10px] truncate">{name}</span>
										</div>
									</div>
									<StatusSelect status={active} />
								</div>
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item<SearchEventType> name="description" label="Descrição">
					<Input />
				</Form.Item>
				<Form.Item<SearchEventType> name="invoice_id" label="Nº Nota Fiscal">
					<Input />
				</Form.Item>
				<Form.Item<SearchEventType> name="event_date" label="Data do evento">
					<DatePicker format="DD/MM/YYYY" />
				</Form.Item>
				<Form.Item>
					<div className="text-right">
						<Space size="small">
							<Button type="default" danger onClick={onCallBackResetFilter}>
								Limpar Filtro
							</Button>
							<Button type="primary" htmlType="submit">
								Filtrar
							</Button>
						</Space>
					</div>
				</Form.Item>
			</Form>
		</>
	);
};
