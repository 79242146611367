import { DatePicker, Form, Select } from "antd";
import { NamePath } from "antd/es/form/interface";
import { DefaultOptionType } from "antd/es/select";
import { FormInstance } from "antd/lib";
import dayjs from "dayjs";
import { useState } from "react";

type FormItemConditionalDateProps<T> = {
  options: DefaultOptionType[];
  attribute: NamePath | NamePath[];
  label: string;
  form: FormInstance<T>;
};

function FormItemConditionalDate<T>({ attribute, label, options, form }: FormItemConditionalDateProps<T>): JSX.Element {
  const [option, setOption] = useState<string | undefined>(undefined);

  const handleSelectChange = (value: string) => {
    /*     const dynamicFieldsValue = attribute.reduceRight(
      (acc: any, key: any, index: any) => {
        return index === attribute.length - 1 ? { [key]: acc } : { [key]: acc };
      },
      { start_date: undefined, end_date: undefined }
    ); */
    setOption(value);
  };

  const filterOptions = Form.useWatch([...attribute, "option"]);

  return (
    <Form.Item<T> label={label} name={attribute} className="p-0 my-2">
      <div className="flex items-center gap-3">
        <Form.Item className="p-0 m-0 w-52" name={[...attribute, "option"]}>
          <Select
            allowClear
            onClear={() => handleSelectChange("")}
            onChange={handleSelectChange}
            options={options}
            value={option}
          />
        </Form.Item>
        <Form.Item
          className="p-0 m-0"
          hidden={option === "less" || filterOptions === "less"}
          name={[...attribute, "start_date"]}
          getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
          normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
        >
          <DatePicker
            placeholder={
              option === "equal" ? "Igual à data" : option === "greater" ? "A partir da data" : "Data inicial"
            }
            format="DD/MM/YYYY"
          />
        </Form.Item>
        <Form.Item
          className="p-0 m-0"
          hidden={
            option === "equal" || option === "greater" || filterOptions === "equal" || filterOptions === "greater"
          }
          name={[...attribute, "end_date"]}
          getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
          normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
        >
          <DatePicker
            maxDate={option === "less" ? dayjs() : undefined}
            placeholder={option === "less" ? "Anterior à data" : "Data final"}
            format="DD/MM/YYYY"
          />
        </Form.Item>
      </div>
    </Form.Item>
  );
}

export default FormItemConditionalDate;
